.App {
  text-align: center;
  height: 100vh;
  background-image: linear-gradient(to right, #ff9a9e, #fad0c4);
}

.main-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
}

.title {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color:white;
  font-size: 48px;
}

.successTitle {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color:white;
  font-size: 36px;
}

.subtitle {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: white;
  font-size: 24px;
  margin: 0px
}

.prompt-box-container {
  margin: 28px;
  width: 350px;
  height: 200px;
  border-radius: 15px;
  background: rgba(255,255,255,0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 1s;
}

.prompt-box-container:hover {
  width: 380px;
  height: 230px;
  box-shadow: 0 12px 50px rgba(0, 0, 0, 0.2);
}

.submit-button {
  background-color: #f8aeb0;
  color: white;
  border-style: none;
  width: 85%;
  height: 15%;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 1s;
}

.submit-button:hover{
  background-color: #9ab5ff;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.341);
  z-index: 2000;
  animation-name: fadeIn;
  animation-duration: 1s;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}


@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

textarea {
  border-style: none;
  outline-style: none;
  width: 80%;
  height: 50%;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 10px;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #ff9a9e; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* For mobile phones: */
@media only screen and (max-width: 430px) {
  .title {
    font-size: 36px;
  }
  
  .subtitle {
    font-size: 18px;
  }

  .successTitle {
    margin-top: 25px;
    font-size: 30px;
  }

  .prompt-box-container:hover {
    width: 350px;
    height: 200px;
    box-shadow: 0 12px 50px rgba(0, 0, 0, 0.2);
  }
}
